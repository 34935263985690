import { SET_CONFIG, SET_LOCATE } from "../Dispatch/type";
import i18n from "../../helpers/i18n";
export const initialState = {
    config: {
        app: {
            name: "Sparrow CMS",
            version: "1.0.0",
            baseURL: "",
            frontendURL: "",
            assetURL: "",
            uploadURL: "",
            mediaURL: "",
            adminPrefix: ""
        }
    },
    language: {
        locales: [],
        locale: "en"
    }
};
export const AuthReducer = (state = initialState, action) => {
    let { type, payload } = action;
    switch (type) {
        case SET_CONFIG:
            //add language
            let { lang } = payload.language;
            Object.entries(lang).forEach(([key, lang]) => {
                Object.entries(lang).forEach(([namespace, resources]) => {
                    i18n.addResourceBundle(key, namespace, resources, true);
                });
            });
            //set locale
            i18n.changeLanguage(payload.language.locale);
            //
            return {
                ...state,
                config: payload.config,
                language: payload.language
            };
        case SET_LOCATE:
            i18n.changeLanguage(payload.locale);
            return {
                ...state,
                language: {
                    locales: state.language.locales,
                    locale: payload.locale
                }
            };
        default:
            return state;
    }
};