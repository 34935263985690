import { Fragment, useContext, useState } from "react";
import { AuthContext } from "../Contexts/AuthContext";
// import SocialButton from "../socialButton";
import { Form, Input, Button, Checkbox, Alert, Row, Spin, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Login = ({ history, ...props }) => {
    const { Login, SocialLogin } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const { t } = useTranslation("auth");

    const login = async values => {
        setError(null);
        setLoadingStatus(true);
        let data = await Login(values);
        // console.log(data)

        setLoadingStatus(false);

        if (!data.status) {
            setError(data.message)
        }

        if (data.error != null) {
            setError(data.error);
        }

        if (data.redirect_to && data.status) {
            window.location.replace(data.redirect_to);
        }
        if (data.next_step != null) {
            const { qr_code } = data;
            props.changeQRCode(qr_code);
            props.changePage(data.next_step);
        }
    };

    const onLoginSocialSuccess = async response => {
        setError(null);
        let data = await SocialLogin(response);
        if (data.error != null) {
            setError(data.error);
        }
        if (data.redirect_to) {
            window.location.replace(data.redirect_to);
        }
        if (data.next_step != null) {
            const { qr_code } = data;
            props.changeQRCode(qr_code);
            props.changePage(data.next_step);
        }
    };

    const onLoginSocialFailure = err => {
        if (typeof err == "string") {
            setError(err);
        }
    };

    const setNodeRef = (provider, node) => {};

    const onFinishFailed = errorInfo => {};

    const goToRegister = () => {
        let urlRegister = baseURL + "/auth/register-account";
        // console.log(urlRegister)
        window.location = urlRegister;
    }

    return (
        <Fragment>
            {error && <Alert message={error} type="error" closable />}
            <div className="title-form">
                LOGIN TO <br/>YOUR ACCOUNT
            </div>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: false
                }}
                onFinish={login}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: t("validation.email_required")
                        }
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder={t("placeholder.email")}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t("validation.password_required")
                        }
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder={t("placeholder.password")}
                    />
                </Form.Item>
                {/* <Form.Item className="inline-flex">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>{t("label.remember_me")}</Checkbox>
                    </Form.Item>
                </Form.Item> */}
                <Row justify="left">
                    {loadingStatus == true ? (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Button className="btn-login-pub" type="primary" htmlType="submit">
                            {t("label.login")}
                        </Button>
                    )}
                </Row>
            </Form>
            <div className="login-box-footer">
                <div>
                    <Button className="button-forgot" onClick={() => props.changePage("forgot")}>
                        Forgot your password?
                    </Button>
                </div>
                <div>
                    <Button className="button-forgot" onClick={() => goToRegister()}>
                        Create new account
                    </Button>
                </div>
            </div>
        </Fragment>
    );

};
export default Login;