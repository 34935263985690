import { Fragment, useContext } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import { Row, Col } from "antd";

import { AuthContext } from "../components/Contexts/AuthContext";

import MySelect from "../components/Fields/MySelect";
import Auth from "../components/Pages/Auth";
import ResetPassword from "../components/Pages/ResetPassword";
import RegisterAccount from "../components/Pages/RegisterAccount";

const addLocationQuery = history => {
    history.location = Object.assign(history.location, {
        query: queryString.parse(history.location.search)
    });
};

const history = createBrowserHistory({ basename: "/" });
addLocationQuery(history);
history.listen(() => {
    addLocationQuery(history);
    if (window.SEND_USAGE_STATS) {
    }
});

export default () => {
    const { data, setLocale } = useContext(AuthContext);
    const { language, user } = data;

    const languageOnChange = value => {
        setLocale({
            locale: value
        });
    };

    return (
        <Fragment>
            <Router history={history}>
                <div className="login-view">
                    {/* <div className="box-language">
                        <MySelect
                            options={language.locales}
                            value={language.locale}
                            width={100}
                            setFieldValue={languageOnChange}
                        />
                    </div> */}
                    <div className="login-view-container">
                        <div className="login-view-left">
                            <div className="logo-box">
                                <a href={baseURL}>
                                    <img src="https://urekamedia.com/themes/frontend/frontend_default/images/logo.png"  className="logo-pub-view" />
                                </a>
                                <p className="login-company-name">SSP For Publishers</p>
                            </div>
                        </div>
                        <div className="login-view-right">
                            <Switch>
                                <Route
                                    exact
                                    path={
                                        window.sparrowConfig.app.frontendPrefix +
                                        "/auth/login"
                                    }
                                    render={props => <Auth {...props} />}
                                />
                                <Route
                                    path={
                                        window.sparrowConfig.app.frontendPrefix +
                                        "/auth/reset-password"
                                    }
                                    render={props => <ResetPassword {...props} />}
                                />
                                <Route
                                    exact
                                    path={
                                        window.sparrowConfig.app.frontendPrefix +
                                        "/auth/register-account"
                                    }
                                    render={props => <RegisterAccount {...props} />}
                                />
                            </Switch>
                        </div>
                    </div>

                </div>
            </Router>
        </Fragment>
    );
};
