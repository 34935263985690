import { Fragment, useContext, useState, useEffect } from "react";
import { AuthContext } from "../Contexts/AuthContext";
// import SocialButton from "../socialButton";
import { Form, Input, Button, Checkbox, Alert, Row, Spin, Col, message } from "antd";
import { MailOutlined, LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Forgot = ({ history, ...props }) => {
    const { forgotUser } = useContext(AuthContext);
    const [error, setError] = useState(null);

    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const [emailValidate, setValidateMail] = useState(false);

    const { t } = useTranslation("auth");

    useEffect(() => {
        setTimeout(() => {
            setLoadingPage(false);
        }, 1000);
    }, [])

    const checkForgot = values => {

        if (!values.email) {
            setError("Missing email, please input email");
            return
        }

        setError(null);
        setLoadingStatus(true);
        forgotUser(values)
            .then(res => {
                setLoadingStatus(false);
                let { status, msg } = res.data;
                if (status === 1){
                    message.success(msg)
                    props.actionChangePage("login");
                } else if (status == 2 || status == 0) {
                    message.error(msg)
                } else {
                    message.warning(msg)
                }
                // setError(msg)
            }).catch(error => { console.log(error) });;
    };

    const onFinishFailed = errorInfo => {
        console.log(errorInfo)
        if (typeof err == "string") {
            setError(errorInfo);
        }
    };

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const inputEmailOnchange = evt => {
        let email = evt.target.value;
        if (validateEmail(email)){
            if (!emailValidate) {
                setValidateMail(true)
            }
        } else {
            if (emailValidate) {
                setValidateMail(false)
            }
        }
    }

    return (
        <Fragment>
            <Spin spinning={loadingPage}>
            {error && <Alert message={error} type="error" closable />}
            <div className="title-form">
                RESET YOUR<br/>PASSWORD
            </div>
            <Form
                name="forgot_form"
                className="login-form"
                initialValues={{
                    email: ''
                }}
                onFinish={checkForgot}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="email"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: t("validation.email_required")
                    //     }
                    // ]}
                >
                    <Input
                        onChange={inputEmailOnchange}
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder={t("placeholder.email")}
                    />
                </Form.Item>

                <Row justify="left">
                    {loadingStatus == true ? (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Button disabled={emailValidate ? false : true} className="btn-login-pub" type="primary" htmlType="submit">
                            {loadingStatus ? `Please wait` : `Submit`}
                        </Button>
                    )}
                </Row>
            </Form>
            <div className="login-box-footer">
                <Button className="button-forgot back-login" onClick={() => props.actionChangePage("login")} icon={<LeftOutlined />}>
                    Back to Login
                </Button>
            </div>
            </Spin>
        </Fragment>
    );

};
export default Forgot;