import { useState } from "react";
import { Button, Card } from "antd";
import Login from "./Login.js";
import Forgot from "./Forgot.js";
import { RollbackOutlined } from "@ant-design/icons";

const Auth = ({ history, ...props }) => {
    const [page, setPage] = useState("login");
    const [qrCode, setQRCode] = useState("");

    const changePage = page => {
        setPage(page);
    };

    const changeQRCode = qrcode => {
        setQRCode(qrcode);
    };

    switch (page) {
        case "verify_google_otp":
            return(
                <Card
                    title={window.sparrowConfig.app.name}
                    className="OTPFormContainer"
                    extra={
                        <Button
                            type="primary"
                            icon={
                                <RollbackOutlined
                                    onClick={() => changePage("login")}
                                />
                            }
                        />
                    }
                >
                    {/* <GoogleOtp qrCode={qrCode} /> */}
                </Card>
            );
        case "verify_otp":
            return(
                <Card
                    title={window.sparrowConfig.app.name}
                    className="OTPFormContainer"
                    extra={
                        <Button
                            type="primary"
                            icon={
                                <RollbackOutlined
                                    onClick={() => changePage("login")}
                                />
                            }
                        />
                    }
                >
                    {/* <Otp /> */}
                </Card>
            )
        case "forgot":
            return (
                <div className="login-box-container">
                    <Forgot
                        actionChangePage={changePage}
                    />
                </div>
            )
        default:
            return(
                <>
                    <div className="login-box-container">
                        <Login
                            changePage={changePage}
                            changeQRCode={changeQRCode}
                        />
                    </div>
                </>
            );
    }
};
export default Auth;