import { useState, useEffect, useContext} from "react";
import { Form, Input, Button, Checkbox, Alert, Row, Spin, Col, message } from "antd";
import { LockOutlined, UserOutlined, LeftOutlined, MailOutlined } from "@ant-design/icons";
import queryString from 'query-string';

import { AuthContext } from "../Contexts/AuthContext";

const RegisterAccount = ({ history, ...props }) => {
    const { createNewAccount } = useContext(AuthContext);

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [actionSuccess, setActionSuccess] = useState(false);

    useEffect(() => {
    }, [])

    const onRegisterAccount = values => {
        if (!values.username || !values.email) return;
        if (values.password !== values.cf_password) return;
        // const formValue = {
        //     password: values.password,
        //     token: tokenReset,
        // };
        // setError(null);
        // setLoadingStatus(true);
        createNewAccount(values).then((res) => {
            console.log(res)
            setLoadingStatus(false);
            let { status, msg } = res.data;
            if (status){
                message.success(msg);
                //done!
                setActionSuccess(true)
            } else {
                message.warning(msg);
            }
        })
    }

    const onFinishFailed = err => {
        if (typeof err == "string") {
            setError(err);
        }
    }

    const goToPage = pageName => {
        if (pageName == 'login') {
            window.location = baseURL;
        }
    }

    if (actionSuccess){
        return (
            <div className="login-box-container">
                <div className="title-form">
                    REGISTER ACCOUNT SUCCESS!
                </div>
                <br/>
                <div className="">
                    Waiting admin process your account!
                </div>
                <div>
                    <a href={baseURL}>GO TO LOGIN PAGE</a>
                </div>
            </div>
        )
    }

    return(
        <>
            <div className="login-box-container">
                <div className="title-form">
                    REGISTER NEW ACCOUNT
                </div>
                <Spin spinning={loadingStatus}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        email: '',
                        username: '',
                        password: '',
                        cf_password: '',
                        location: '',
                    }}
                    onFinish={onRegisterAccount}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'User name is required!'
                            }
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={`User name`}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Email input not valid!'
                            }
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            placeholder={`Email`}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                min: 8,
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder={"Password"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="cf_password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // console.log(value)
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder={"Confirm password"}
                            // onChange={onchangeConfirmPwd}
                        />
                    </Form.Item>
                    {/* <br/> */}
                    <Row justify="left">
                        <Button className="btn-login-pub" type="primary" htmlType="submit">
                            Register
                        </Button>
                    </Row>
                </Form>
                {/* action */}
                <div className="action-container">
                    <br/>
                    <Button className="button-forgot back-login" onClick={() => goToPage('login')} icon={<LeftOutlined />}>
                        Back to Login
                    </Button>
                </div>
                </Spin>
            </div>
        </>
    );
};

export default RegisterAccount;