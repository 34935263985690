import { useState, useEffect, useContext} from "react";
import { Form, Input, Button, Checkbox, Alert, Row, Spin, Col, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import queryString from 'query-string';

import { AuthContext } from "../Contexts/AuthContext";

const ResetPassword = ({ history, ...props }) => {
    const { resetPassword } = useContext(AuthContext);

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [tokenReset, setToken] = useState("");
    const [pwd, setPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [actionSuccess, setActionSuccess] = useState(false);

    useEffect(() => {
        let params = queryString.parse(props.location.search);
        let token = params.token;
        console.log(token)
        if (token){
            setToken(token)
        }
    }, [])


    const updatePassword = values => {
        if (!values.password || !values.new_password) return;
        if (values.password !== values.new_password) return;
        const formValue = {
            password: values.password,
            token: tokenReset,
        };
        // setError(null);
        setLoadingStatus(true);
        resetPassword(formValue).then((res) => {
            console.log(res)
            let { status, msg } = res.data;
            if (status){
                message.success(msg);
                //done!
                setActionSuccess(true)
            } else {
                message.warning(msg);
            }
        })
    }

    const onFinishFailed = err => {
        if (typeof err == "string") {
            setError(err);
        }
    }

    const onChangePwd = (evt) => {
        let pwd = evt.target.value;
        setPwd(pwd)
    }
    const onchangeConfirmPwd = () => {
        let pwd = evt.target.value;
        setConfirmPwd(pwd)
    }

    if (actionSuccess){
        return (
            <div className="login-box-container">
                <div className="title-form">
                    UPDATE YOUR ACCOUNT <br/>NEW PASSWORD
                </div>
                <br/>
                <div className="">
                    Reset password for account success!
                </div>
                <div>
                    <a href={baseURL}>GO TO LOGIN PAGE</a>
                </div>
            </div>
        )
    }

    return(
        <>
            <div className="login-box-container">
                <div className="title-form">
                    UPDATE YOUR ACCOUNT <br/>NEW PASSWORD
                </div>
                <Spin spinning={loadingStatus}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        password: '',
                        new_password: '',
                    }}
                    onFinish={updatePassword}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                min: 8,
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder={"New password"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="new_password"
                        dependencies={['password']}
                        // hasFeedback
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    // console.log(value)
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                },
                              }),
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder={"Confirm password"}
                            // onChange={onchangeConfirmPwd}
                        />
                    </Form.Item>
                    <br/>
                    <Row justify="left">
                        <Button className="btn-login-pub" type="primary" htmlType="submit">
                            Reset password
                        </Button>
                    </Row>
                </Form>
                </Spin>
            </div>
        </>
    );
};

export default ResetPassword;