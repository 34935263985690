import { useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import routes from "../../routes/index";
import { AuthContext } from "../Contexts/AuthContext";

const App = () => {
    const { getConfig, data } = useContext(AuthContext);

    useEffect(() => {
        getConfig();
    }, []);
    
    return routes();
};
export default App;
