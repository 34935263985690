import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    // we init with resources
    resources: {
    },
    fallbackLng: "en",

    // have a common namespace used around the full app
    //keySeparator: false, // we use content as keys
    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },
    react: {
        wait: true
    },
    debug: false
});

export default i18n;
