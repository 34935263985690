import React, { createContext, useReducer } from "react";
import { initialState, AuthReducer } from "../Reducers/AuthReducer";
import { SET_CONFIG, SET_LOCATE } from "../Dispatch/type";
import api from "../../helpers/api";
export const AuthContext = createContext();

const AuthContextProvider = ({ children, axios, history }) => {
    const [data, dispatch] = useReducer(AuthReducer, initialState);

    const getConfig = () => {
        return api
            .getSecured()
            .get(`/get-config`)
            .then(res => {
                let { language, config } = res.data;

                dispatch({
                    type: SET_CONFIG,
                    payload: {
                        config: config,
                        language: language
                    }
                });
            }).catch(error => { console.log(error) });
    };

    const setLocale = values => {
        return api
            .getSecured()
            .post(`/change-locate`, values)
            .then(res => {
                let { status } = res.data;
                if (status) {
                    dispatch({
                        type: SET_LOCATE,
                        payload: values
                    });
                }
            });
    };

    const Login = async values => {
        try {
            let email = values.email || "";
            let password = values.password || "";
            let remember = values.remember || false;
            const { data } = await api.getSecured().post(`/login`, {
                email,
                password,
                remember
            });
            return {
                error: null,
                ...data
            };
        } catch (err) {
            return {
                error: err.message
            };
        }
    };

    const SocialLogin = async response => {
        try {
            let provider = response.provider || "";
            let profile = response.profile || {};
            let token = response.token || {};
            const { data } = await api.getSecured().post("/socialLogin", {
                provider,
                profile,
                token
            });
            return {
                error: null,
                ...data
            };
        } catch (err) {
            return {
                error: err.message
            };
        }
    };

    const forgotUser = values => {
        return api
            .getSecured()
            .post(`/userForgotPassword`, values)
    };

    const resetPassword = values => {
        return api
            .getSecured()
            .post(`/updateNewPassword`, values)
    }

    const createNewAccount = values => {
        return api
            .getSecured()
            .post(`/request-new-account`, values)
    }

    const todoContextData = {
        data, getConfig, setLocale, Login, SocialLogin,
        forgotUser, resetPassword, createNewAccount,
        dispatch
    };

    return (
        <AuthContext.Provider value={todoContextData}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContextProvider;