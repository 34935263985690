import Promise from "bluebird";
import axios from "axios";
import _ from "lodash";

import { toast } from "react-toastify";
import BaseError from "../components/BaseError";

const defaultOptions = {
  timeout: 10000
};

const createClient = (clientOptions, { toastErrors, addInterceptor }) => {
  const client = axios.create({
    ...defaultOptions,
    ...clientOptions
  });

  const onSuccess = response => {
    //console.log("response", response);
    const statusCode = response.status;
    if (statusCode != 200) {
      return Promise.reject(
        new BaseError(
          statusCode,
          response.data.message,
          []
        )
      );
    }
    return response;
  };

  const onError = error => {
    if (!error.response) {
      return Promise.reject(new BaseError(-1, "Network error"));
    }
    const statusCode = error.response.status;
    if (statusCode == 419) {
      window.location.reload();
    }
    if (toastErrors) {
      if (error.response.data.error.message) {
        showToast(error.response.data.error.message);
      } else {
        showToast("Something wrong happened. Please try again later.");
      }
    }
    return Promise.reject(
      new BaseError(
        statusCode,
        error.response.data.error.message,
        error.response.data.error.data
      )
    );
  };
  client.interceptors.response.use(onSuccess, onError);
  return client;
};

const showToast = message => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT
  });
};

const overrideApiUrl = baseURL
  ? { baseURL: `${baseURL}/auth` }
  : { baseURL: `/auth` };

export default {
  getApiPath() {
    return overrideApiUrl.baseURL;
  },
  getSecured({ token, toastErrors = true, timeout = 10000 } = {}) {
    if (!token) {
      token = window.axios.defaults.headers.common["X-CSRF-TOKEN"];
    }
    return createClient(
      {
        timeout,
        headers: {
          "X-CSRF-TOKEN": token
        },
        ...overrideApiUrl
      },
      { toastErrors }
    );
  },

  getAnonymous({ toastErrors = true, timeout = 10000 } = {}) {
    overrideApiUrl.timeout = timeout;
    return createClient(overrideApiUrl, { toastErrors });
  },

  getStripePath() {
    return overrideStripePath;
  }
};
